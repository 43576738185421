


























































































import { defineComponent } from '@vue/composition-api'
import { mapFields } from 'vuex-map-fields'
import { mapActions, mapState } from 'vuex'
import { ValidationObserver } from 'vee-validate'
import { userConnectedHasRole } from '@/composables/UserGrant'

export default defineComponent({
  setup () {
    const canCreateEventDay = () => userConnectedHasRole('ROLE_WRITE_EVENT_DAY')
    return {
      canCreateEventDay,
    }
  },
  name: 'createEventDay',
  components: {
    DateField: () => import('@/components/base/DateField.vue'),
    SelectArea: () => import('@/components/molecules/select/area.vue'),
  },
  data () {
    return {
      errors: [],
      dialog: false,
    }
  },
  async created () {
    await this.init()
  },
  computed: {
    ...mapFields('eventDayForm', [
      'eventDay.name',
      'eventDay.dateEvent',
      'eventDay.areas',
    ]),
    ...mapState('eventDayForm', {
      isEventDaySubmitted: 'isSubmitted',
      isFormChanged: 'isChanged',
      eventDay: 'eventDay',
    }),
    ...mapState('area', {
      listAreas: 'list',
    }),
  },
  methods: {
    async init () {
      await this.reset()
    },
    ...mapActions('eventDayForm', {
      save: 'save',
      reset: 'reset',
    }),
    ...mapActions('user', {
      sendSuccessMessage: 'sendSuccessMessage',
    }),
    ...mapActions('eventDay', {
      loadEventDays: 'load',
    }),
    getValidationObserver () {
      return this.$refs.validationObserver as InstanceType<typeof ValidationObserver>
    },
    async onSubmit () {
      const isValidatedForm = await this.getValidationObserver().validate()
      if (isValidatedForm) {
        await this.save(this.eventDay)
        await this.sendSuccessMessage('event_day.form.saved')
        await this.loadEventDays()
        this.toggle()
      }
    },
    toggle () {
      this.dialog = !this.dialog
      this.reset()
      this.getValidationObserver().reset()
    },
  },
})
